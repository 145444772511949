import {
  Flex,
  Link,
  LogotypeSecondary,
  Spacer,
  Text,
} from '@iheartradio/web.companion';
import { Link as RouterLink, useSearchParams } from '@remix-run/react';
import { route } from 'routes-gen';

type RegistrationHeaderProps = {
  redirectUrl: string;
  mode: 'sign-up' | 'login' | 'social-login';
};

export const RegistrationHeader = ({
  redirectUrl,
  mode,
}: RegistrationHeaderProps) => {
  const [searchParams] = useSearchParams();
  const queryString =
    searchParams.size > 0 ? `?${searchParams.toString()}` : '';

  const { title, description, otherFlow, otherCta, dataTest } =
    mode === 'sign-up'
      ? {
          dataTest: 'login-link',
          title: 'Sign up for free',
          description: 'Already have an account?',
          otherFlow: route('/login') + queryString,
          otherCta: 'Log in',
        }
      : {
          dataTest: 'signup-free-link',
          title: 'Log in',
          description: "Don't have an account?",
          otherFlow: route('/sign-up') + queryString,
          otherCta: 'Sign up for free',
        };
  const showText = mode !== 'social-login';

  return (
    <Flex
      alignItems="flex-start"
      direction="column"
      gap="$8"
      paddingLeft="$24"
      paddingTop="$24"
    >
      <Link as={RouterLink} role="link" to={redirectUrl}>
        <LogotypeSecondary aria-label="iHeart Logo" data-test="logo" />
      </Link>
      {showText ? (
        <>
          <Spacer top="$8" />
          <Text
            as="h2"
            kind={{ '@initial': 'h3', '@small': 'h3', '@medium': 'h2' }}
          >
            {title}
          </Text>
          <Text
            as="p"
            color={{ light: '$gray-500', dark: '$gray-200' }}
            kind={{ '@initial': 'subtitle-4', '@medium': 'subtitle-2' }}
          >
            {description}{' '}
            <Link
              as={RouterLink}
              color="primary"
              data-test={dataTest}
              role="link"
              to={otherFlow}
              underline="always"
            >
              {otherCta}
            </Link>
          </Text>
        </>
      ) : null}
    </Flex>
  );
};
