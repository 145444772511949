import {
  Background,
  Box,
  Flex,
  Spacer,
  useMediaQuery,
} from '@iheartradio/web.companion';

import { RegistrationHeader } from '~app/components/registration-header';
import { useRedirectUrl } from '~app/hooks/use-redirect/use-redirect';

import { ForgotResetPasswordHeader } from './forgot-reset-password-header';

interface SignupLoginContainerProps {
  mode?:
    | 'sign-up'
    | 'login'
    | 'forgot-password'
    | 'reset-password'
    | 'social-login';
  children?: React.ReactNode;
}

export const SignUpLoginContainer = ({
  children,
  mode = 'sign-up',
}: SignupLoginContainerProps) => {
  const { redirectUrl } = useRedirectUrl();

  const isMobile = useMediaQuery('(pointer: coarse)');
  const minHeight = mode === 'sign-up' ? '80rem' : '50rem';

  return (
    <Box data-test="sign-up-login-container" height="100vh">
      <Flex alignItems="center" direction="row" justifyContent="center">
        <Flex
          alignItems="center"
          direction="column"
          height="100vh"
          justifyContent={{ '@initial': 'flex-start', '@large': 'center' }}
          minHeight={minHeight}
          width={{
            '@xsmall': '100vw',
            '@small': '100vw',
            '@large': '50vw',
          }}
        >
          <Box maxWidth="46.8rem" width="100%">
            {mode === 'sign-up' ||
            mode === 'login' ||
            mode === 'social-login' ? (
              <RegistrationHeader mode={mode} redirectUrl={redirectUrl} />
            ) : (
              <ForgotResetPasswordHeader
                mode={mode}
                redirectUrl={redirectUrl}
              />
            )}
            <Spacer top={mode === 'social-login' ? '$16' : '$32'} />
            {children}
          </Box>
        </Flex>
        <Background
          aspectRatio={1}
          data-test="bg"
          display={{
            '@initial': 'none',
            '@xlarge': 'block',
          }}
          height="100vh"
          image={isMobile ? undefined : '/sign-up-full.jpg'}
          minHeight={minHeight}
          overflow="hidden"
          position="50% 0"
          width="50vw"
        />
      </Flex>
    </Box>
  );
};
