import {
  Flex,
  Link,
  LogotypeSecondary,
  Text,
} from '@iheartradio/web.companion';
import { Link as RouterLink } from '@remix-run/react';

type ForgotResetPasswordHeaderProps = {
  redirectUrl: string;
  mode?: 'forgot-password' | 'reset-password';
};

export const ForgotResetPasswordHeader = ({
  redirectUrl,
  mode = 'forgot-password',
}: ForgotResetPasswordHeaderProps) => {
  const { title, description } =
    mode === 'forgot-password'
      ? {
          title: 'Forgot password?',
          description: 'Enter your email address to reset your password.',
        }
      : {
          title: 'Create a New Password',
          description: null,
        };

  return (
    <Flex
      alignItems="flex-start"
      direction="column"
      gap="$8"
      paddingLeft="$24"
      paddingTop="$24"
    >
      <Link as={RouterLink} role="link" to={redirectUrl}>
        <LogotypeSecondary aria-label="iHeart Logo" data-test="logo" />
      </Link>
      <Text
        as="h2"
        data-test="title"
        kind={{ '@initial': 'h3', '@small': 'h3', '@medium': 'h2' }}
      >
        {title}
      </Text>
      {description ? (
        <Text
          as="p"
          color={{ light: '$gray-500', dark: '$gray-200' }}
          data-test="description"
          kind={{ '@initial': 'subtitle-4', '@medium': 'subtitle-2' }}
        >
          {description}
        </Text>
      ) : null}
    </Flex>
  );
};
